import React from "react";
import { useRouter } from "next/router";
import { useUser } from "contexts/UserContext";
import FullScreenSpinner from "components/organisms/FullScreenSpinner/FullScreenSpinner";

import { SubscriptionStatuses } from "types";
import { useTeam } from "contexts/TeamContext";
import useTranslation from "next-translate/useTranslation";
import { DEFAULT_PORTAL_NAME } from "utils/constants";
import { getPortalByCustomDomain } from "utils/getPortalByCustomDomain";
import Portal from "models/Portal";
import { isInternalDomain } from "utils/isInternalDomain";

export default (WrappedComponent: any) => {
  const hocComponent = ({ ...props }) => {
    const { t } = useTranslation("common");
    const {
      user,
      isLoading,
      isFetched,
      isAdmin,
      subscription,
      subscriptionFetched,
    } = useUser();
    const { currentTeam, teamId } = useTeam();
    const router = useRouter();
    const { portalName = DEFAULT_PORTAL_NAME } = router.query;
    const redirectIfPlanFinishedTo = `/${portalName}/${teamId}/subscription-expired`;

    const isSubscriptionUnpaid =
      currentTeam &&
      currentTeam.limits?.subscriptionStatus === SubscriptionStatuses.UNPAID;

    // subscription existance check
    const isEmptySubscription =
      !!user && subscriptionFetched && !isAdmin && !subscription;

    if (
      typeof window !== "undefined" &&
      !isLoading &&
      isFetched &&
      (!user || isEmptySubscription)
    ) {
      if (!isInternalDomain) {
        getPortalByCustomDomain(window.location.host, (portal: Portal) => {
          router.push(
            `/${
              portal.customDomain
                ? portal.customDomain.slug
                : portal.portalUrlSlug || portal.domain
            }/auth/login`
          );
        });
      } else {
        let path = `/${portalName}/auth/login`;
        // if (router.asPath !== path) {
        //   path += `?redirectUrl=${router.asPath}`;
        // }
        router.push(path);
      }
      return <FullScreenSpinner text={t("checking_your_credentials")} />;
    }

    if (user && currentTeam && isSubscriptionUnpaid) {
      router.push(redirectIfPlanFinishedTo);
      return <FullScreenSpinner text={t("checking_subscription")} />;
    }

    if (!user) {
      return <FullScreenSpinner text={t("loading_user")} />;
    }

    return <WrappedComponent {...props} />;
  };

  return hocComponent;
};
