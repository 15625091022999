
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { useTeam } from "contexts/TeamContext";
import withProtectedRoute from "HOCs/withProtectedRoute";
import Portal from "models/Portal";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { DEFAULT_PORTAL_NAME, HOME_ROUT } from "utils/constants";
import { getPortalByCustomDomain } from "utils/getPortalByCustomDomain";
import { getServerSideMetaData, MetaDataProps } from "utils/getServerSideProps";
import { isInternalDomain } from "utils/isInternalDomain";

 const _getServerSideProps: GetServerSideProps<MetaDataProps> =
  getServerSideMetaData;

const Index = () => {
  const { currentTeam } = useTeam();
  const router = useRouter();

  useEffect(() => {
    if (currentTeam && router.pathname === "/") {
      if (!isInternalDomain) {
        getPortalByCustomDomain(window.location.host, (portal: Portal) => {
          router.push(
            `/${
              portal.customDomain
                ? portal.customDomain.slug
                : portal.portalUrlSlug || portal.domain
            }`
          );
        });
      } else {
        router.push(`/${DEFAULT_PORTAL_NAME}${HOME_ROUT}`);
      }
    }
  }, [router, currentTeam]);

  return null;
};

export default withProtectedRoute(Index);


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  