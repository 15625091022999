import axios from "axios";
import Portal from "models/Portal";

export const getPortalByCustomDomain = async (
  domain: string,
  onComplete?: (data: Portal) => void
) => {
  try {
    return await axios
      .request({
        method: "GET",
        url: `${process.env.NEXT_PUBLIC_API_HOST}/portals/get-by-domain/${domain}`,
      })
      .then((res) => {
        if (onComplete) {
          return onComplete(res.data);
        }

        return res.data;
      });
  } catch (error) {
    console.log("get portal by custom domain", error);
  }
};
